.pageContentContainer {
	flex-direction: row;
}

.rightPannelContainer {
	display: flex;
	position: relative;
	width: 60%;
}

.rightPannel {
	position: sticky;
	height: 98vh;
	width: 100%;
	top: 1vh;
}

.pannelCardHeader {
	overflow: auto;
	white-space: pre-wrap;
}

.data-display {
	font-family: 'Arial', sans-serif;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 8px;
	margin: 15px;
	background-color: #f8f8f8;
  }
  
  .data-item {
	margin: 10px 0;
	border-bottom: 1px solid #ddd;
	padding-bottom: 5px;
	display: flex;
	justify-content: space-between;
  }
  
  .key {
	margin-right: 10px;
	color: #555;
  }
  
  .value {
	flex-grow: 1;
  }
  
  