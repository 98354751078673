.rightDrawerContainer {
	position: fixed;
	top: 0;
	right: 0;
	width: 30vw;
	height: 100%;
	z-index: 1500;
	padding: 20px;
}

.rightDrawer {
	height: 100%;
}
